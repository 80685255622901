
import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";

export default defineComponent({
  name: "kt-widget-5",
  props: {
    widgetClasses: String,
    activity: Array
  },
  data(){
    return {
      users: []
    }
  },
  mounted(){
    MenuComponent.reinitialization();
  }
});
